// fix valerio madness
// $component-active-bg: theme-color("primary") !default;
$spacer: 1rem;


// // Color system
$theme-colors: (
  "error" : #f00
);

// // Spacing
// $spacers: ();

// Body
$body-bg:           #fff;
$body-bg-light:     #F1EFE9;
$body-color:        #242020;
$body-color-light:  #9B9B9B;

// Links
$link-color:        $body-color;
$link-decoration:   underline;
$link-hover-color:  lighten($link-color, 50%);
$link-transition:   color .2s ease;

// Fonts
$font-family-sans-serif:  'Open Sans', sans-serif;
$font-family-serif:       'Vollkorn', serif;
$font-family-base:         $font-family-sans-serif;

$font-size-base:           1rem;
$font-size-sm:             ($font-size-base * .875);
$font-size-lg:             ($font-size-base * 1.125);
$font-size-xl:             ($font-size-base * 1.25);
$font-size-xxl:            ($font-size-base * 1.375);

$font-weight-light:        300;
$font-weight-normal:       400;
$font-weight-semi-bold:    600;
$font-weight-bold:         700;
$font-weight-extra-bold:   800;

$line-height-base:          1.44;

$paragraph-margin-bottom:   1.25rem;

$headings-font-family:      $font-family-serif;
$headings-font-weight:      $font-weight-normal;
$headings-line-height:      1.2;
$headings-margin-bottom:    1em;

$lead-font-size:            ($font-size-base * 1.18);
$lead-font-weight:          $font-weight-normal;
$lead-line-height:          1.5;

$small-font-size:           $font-size-sm;

$letter-spacing-sans: -0.03em;

// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1690px,
);

$grid-gutter-width-base: 32px;
$grid-gutter-width-large: 48px; // custom variables
$container-max-width: 1252px; // custom variable

$grid-gutter-widths: (
  xs: 16px,
  sm: 32px,
  md: 32px,
  lg: $grid-gutter-width-large,
  xl: $grid-gutter-width-large,
  xxl: $grid-gutter-width-large
);

$grid-container-paddings: (
  xs: 16px,
  sm: 32px,
  md: 32px,
  lg: 48px,
  xl: 96px,
  xxl: 128px
);


// Forms

$alert-border-radius: 0;

$input-border-radius:             0px;
$input-border-radius-lg:          0px;
$input-border-radius-sm:          0px;

$input-bg:                  rgba(#D8D8D8, .3);
$input-border-color:        #979797;
$input-border-width:        1px;
$input-color:               $body-color;
$input-focus-bg:            $input-bg;
$input-focus-color:         $input-color;
$input-focus-border-color:  $input-border-color;
$input-box-shadow:          none;
$input-focus-box-shadow:    0 0 0 0.2rem rgba(0, 123, 255, 0.25); // 0 0 0 .2rem rgba($component-active-bg, .25) !default;
$input-placeholder-color:   $input-color;

$form-group-margin-bottom:  1.25rem;

$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $input-bg;
$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   0 .25rem .25rem rgba(#000, .1);

$custom-control-indicator-checked-color:      $input-color;
$custom-control-indicator-checked-bg:         $input-bg;
$custom-control-indicator-box-shadow:         none;
$custom-control-indicator-focus-box-shadow:   $input-focus-box-shadow;

$custom-checkbox-indicator-border-radius:         0;
$custom-checkbox-indicator-icon-checked:          str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indicator-indeterminate-bg:      $input-bg;
$custom-checkbox-indicator-indeterminate-color:   $input-color;
$custom-checkbox-indicator-icon-indeterminate:    str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");

$custom-radio-indicator-icon-checked:             str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23");

$custom-select-indicator-color:     $input-color;
$custom-select-color:               $input-color;
$custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-bg:                  $input-bg;
// $custom-select-border-width:        $input-btn-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $input-border-radius;
$custom-select-focus-border-color:  $input-focus-border-color;

$custom-select-focus-box-shadow:    $input-focus-box-shadow;

$modal-content-border-radius: 0;
$modal-inner-padding: 3rem;
$modal-content-border-color: transparent;
$modal-content-border-width: 0;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-y:                0;
$dropdown-border-color:             rgba($body-color, .2);
$dropdown-border-radius:            0;
$dropdown-link-color:               $link-color;
$dropdown-link-hover-color:         $link-hover-color;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-color:        $dropdown-link-color;
$dropdown-link-active-bg:           #e9ecef; //$gray-200;
$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

// Components
$section-margin-base:   $spacer * 4;
$section-margin-medium: $spacer * 8;
$section-margin-large:  $spacer * 12;

$header-height-base:     90px;
$header-padding-base:    18px;
$header-height-large:    122px;
$header-padding-large:   34px;

$page-header-min-height: 70vh;
$page-header-min-height-portrait: 55vh;
$page-header-full-min-height: 80vh;
$page-header-full-min-height-portrait: 70vh;

// $page-header-min-height:           450px;
// $page-header-min-height-md:        700px;
// $page-header-min-height-lg:        580px;

// $page-header-min-full-height:      450px;
// $page-header-min-full-height-md:   708px;
// $page-header-min-full-height-lg:   824px;

// $page-header-padding:         $spacer * 5.5;
// $page-header-padding-md:      $spacer * 10;
// $page-header-padding-lg:      $spacer * 7.2;


$modularscale: (
  base: 14px,
  ratio: 50at24,
  map-get($grid-breakpoints, xs): (
    base: 14px,
    ratio: 50at24,
  ),
  map-get($grid-breakpoints, sm): (
    base: 14px,
    ratio: 50at24
  ),
  map-get($grid-breakpoints, md): (
    base: 16px,
    ratio: 90at24
  ),
  map-get($grid-breakpoints, lg): (
    base: 16px,
    ratio: 90at24
  ),
  map-get($grid-breakpoints, xl): (
    base: 18px,
    ratio: 120at24
  ),
  map-get($grid-breakpoints, xxl): (
    base: 18px,
    ratio: 140at24
  )
);
